.events-container {
     width: 100%;
     background-color: #991737;
     padding-bottom: 1rem;
}

.events-container > h1 {
     width: 100%;
     text-align: center;
     font-size: 50px;
     color: white;
     text-transform: uppercase;
}

.events-container > div {
     display: flex;
     width: 80%;
     margin: auto;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
}
.events-container > div > div {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 0.7rem;
     margin: 0.5rem;
}

.events-container > div > div > img {
     width: 70%;
}

.events-container > div > div > h6 {
     color: white;
     font-size: 20px;
     text-align: center;
}
