* {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     font-family: "Montserrat";
}

a {
     text-decoration: none;
     color: inherit;
}

a:hover,
a:active {
     color: initial;
}

.app-container {
     width: 100vw;
}
