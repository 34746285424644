.nav-container {
     width: 100%;
     height: 5rem;
     display: flex;
     justify-content: space-around;
     background-color: #fceddc;
}

.nav-logo {
     height: 100%;
}

.nav-logo > img {
     height: 100%;
}

.nav-menu > ul {
     display: flex;
     justify-content: space-evenly;
     align-items: center;
}

.nav-menu > ul > li {
     list-style: none;
     margin: 1.3rem;
     font-weight: 500;
     font-size: 20px;
     cursor: pointer;
     border-radius: 27px;
     padding: 0.3rem 1rem;
}

.nav-menu > ul > li:hover {
     background-color: rgba(0, 0, 0, 0.1);
}

#nav-login-btn {
     background-color: #2e1739;
     color: white;
}
